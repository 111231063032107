document.addEventListener("DOMContentLoaded", function (event) {
  var html = document.querySelector("html");
  var body = document.querySelector("body");
  var wpadminbar = document.querySelector("#wpadminbar");
  var header = document.querySelector(".c-main-header");
  var logoImg = document.querySelector(".c-main-header__logo-img");
  var mainHeaderHamburger = document.querySelector(".c-main-header__hamburger");
  var menu = document.querySelector(".c-header-menu");
  var menuSection = document.querySelector(".c-header-menu__list");
  var menuSubs = document.querySelectorAll(".c-header-menu__menu-subs");

  var subMenu;
  var wpadmin = body.classList.contains("wp-admin");

  if (header !== null) {
    var mainHeaderHeight = header.getBoundingClientRect().height;
  }
  // if (wpadminbar !== null) {
  //     var wpadminbarHeight = wpadminbar.getBoundingClientRect().height;
  //     html.style.marginTop = wpadminbarHeight + 'px';
  // } else {
  //     var wpadminbarHeight = 0;
  //     html.style.marginTop = wpadminbarHeight + 'px';
  // }

  /*******************************************/
  /* RESIZE FUNCTIONS */
  let mq_sm = window.matchMedia("(max-width: 575px)");
  mq_sm.addListener(function (mql) {
    if (mql.matches) {
      screenResize();
    }
  });
  let mq_md = window.matchMedia("(min-width: 576px) and (max-width: 767px)");
  mq_md.addListener(function (mql) {
    if (mql.matches) {
      screenResize();
    }
  });
  let mq_mobileadminbar = window.matchMedia("screen and (max-width: 782px)");
  mq_mobileadminbar.addListener(function (mql) {
    if (mql.matches) {
      screenResize();
    }
  });
  let mq_standardadminbar = window.matchMedia("screen and (min-width: 783px)");
  mq_standardadminbar.addListener(function (mql) {
    if (mql.matches) {
      screenResize();
    }
  });
  let mq_lg = window.matchMedia("(min-width: 768px) and (max-width: 991px)");
  mq_lg.addListener(function (mql) {
    if (mql.matches) {
      screenResize();
    }
  });
  let mq_xl = window.matchMedia("(min-width: 992px) and (max-width: 1199px)");
  mq_xl.addListener(function (mql) {
    if (mql.matches) {
      screenResize();
    }
  });
  let mq_custom_xl = window.matchMedia(
    "(min-width: 1200px) and (max-width: 1365px)"
  );
  mq_custom_xl.addListener(function (mql) {
    if (mql.matches) {
      screenResize();
    }
  });
  let mq_xxl = window.matchMedia("(min-width: 1366px) and (max-width: 1399px)");
  mq_xxl.addListener(function (mql) {
    if (mql.matches) {
      screenResize();
    }
  });
  let mq_xxxl = window.matchMedia("(min-width: 1400px)");
  mq_xxxl.addListener(function (mql) {
    if (mql.matches) {
      screenResize();
    }
  });
  /* END RESIZE FUNCTIONS */
  /*******************************************/
  function debounce(func, wait, immediate) {
    /* debounce */
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
  /*******************************************/
  function screenResize() {
    // if (wpadminbar) {
    //     wpadminbarHeight = wpadminbar.getBoundingClientRect().height;
    //     html.style.marginTop = wpadminbarHeight + 'px';
    // } else {
    //     wpadminbarHeight = 0;
    //     html.style.marginTop = wpadminbarHeight + 'px';
    // }

    /* TURN OFF MOBILE MENU ON DESKTOP RES */
    if (
      menu !== null &&
      (mq_xl.matches ||
        mq_custom_xl.matches ||
        mq_xxl.matches ||
        mq_xxxl.matches)
    ) {
      mainHeaderHamburger.classList.remove("c-main-header__hamburger--is-open");
      logoImg.classList.remove("c-main-header__logo-img--menu-is-open");
      if (menu.classList.contains("c-header-menu--is-active")) {
        toggleMenu();
      }
      if (menuSubs !== null) {
        menuSubs.forEach(function (e) {
          e.style.animation = "";
        });
      }
    }
  }
  /*******************************************/
  function toggleMenu() {
    body.classList.toggle("disable-scroll");
    body.classList.toggle("menu-mobile--is-active");
    menu.classList.toggle("c-header-menu--is-active");
    mainHeaderHamburger.classList.toggle("c-main-header__hamburger--is-open");
  }
  /*******************************************/
  scrollToSmoothly = (pos, time) => {
    var currentPos = window.pageYOffset;
    var start = null;
    if (time == null) time = 500;
    (pos = +pos), (time = +time);
    history.pushState("", document.title, window.location.pathname);
    window.requestAnimationFrame(function step(currentTime) {
      start = !start ? currentTime : start;
      var progress = currentTime - start;
      if (currentPos < pos) {
        window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
      } else {
        window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
      }
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    });
  };
  /********************************************/
  function initScripts() {
    if (mainHeaderHamburger !== null) {
      mainHeaderHamburger.addEventListener("click", function () {
        toggleMenu();
      });
    }

    if (!wpadmin) {
      document.querySelectorAll('a[href^="#"]').forEach((trigger) => {
        trigger.onclick = function (e) {
          e.preventDefault();
          const hash = e.currentTarget.getAttribute("href").split("#");
          const scrollTarget = document.querySelector("#" + hash[1]);
          scrollToSmoothly(scrollTarget.offsetTop - mainHeaderHeight, 1000);
          if (menu.classList.contains("c-header-menu--is-active")) {
            toggleMenu();
          }
        };
      });
    }

    if (menuSection !== null) {
      const linkParent = document.querySelectorAll("a.menu-item-has-children");
      linkParent.forEach((link) => {
        link.setAttribute("href", "#");
        link.addEventListener("click", (e) => {
          e.preventDefault();
        });
      });

      menuSection.addEventListener("click", (e) => {
        if (!menu.classList.contains("c-header-menu--is-active")) {
          return;
        }
        if (e.target.closest(".c-header-menu__list-item--has-children")) {
          const hasChildren = e.target.closest(
            ".c-header-menu__list-item--has-children"
          );
          if (
            e.target.classList.contains(
              "c-header-menu__list-item-arrow-down"
            ) ||
            e.target.classList.contains(
              "c-header-menu__list-item-arrow-down-ico"
            )
          ) {
            e.preventDefault();
            document
              .querySelectorAll(".c-header-menu__menu-subs")
              .forEach((el) => {
                el.classList.remove("c-header-menu__menu-subs--is-active");
              });
            const subMenu = hasChildren.querySelector(
              ".c-header-menu__menu-subs"
            );
            if (
              subMenu.classList.contains("c-header-menu__menu-subs--is-active")
            ) {
              setTimeout(() => {
                subMenu.classList.remove("c-header-menu__menu-subs--is-active");
              }, 300);
            } else {
              subMenu.classList.add("c-header-menu__menu-subs--is-active");
            }
          }
        }
      });
    }

    if (!wpadmin) {
      if (window.location.hash) {
        const hash = window.location.href.split("#")[1];
        const scrollTarget = document.querySelector("#" + hash);
        scrollToSmoothly(scrollTarget.offsetTop - 50, 1000);
      }
    }
  }

  /* Checked scroll menu */

  const fixedMenu = () => {
    const header = document.querySelector(".js-header");
    if (header) {
      const doc = document.documentElement;
      const checkScroll = () => {
        let curScroll = window.scrollY || doc.scrollTop;

        if (curScroll > 150) header.classList.add("is-fixed");
        else header.classList.remove("is-fixed");
      };

      window.addEventListener("scroll", checkScroll);
    }
  };

  fixedMenu();

  /*******************************************/
  if (!wpadmin) {
    initScripts(); /* INITALIZE SCRIPTS */
    screenResize(); /* ON SCREEN RESIZE ACTIONS */
  } else {
    if (window.acf) {
      window.acf.addAction("render_block_preview", function () {
        html = document.querySelector("html");
        body = document.querySelector("body");
        wpadminbar = document.querySelector("#wpadminbar");
        header = document.querySelector(".c-main-header");
        logoImg = document.querySelector(".c-main-header__logo-img");
        mainHeaderHamburger = document.querySelector(
          ".c-main-header__hamburger"
        );
        menu = document.querySelector(".c-header-menu");
        menuSection = document.querySelector(".c-header-menu__list");
        menuSubs = document.querySelectorAll(".c-header-menu__menu-subs");
        menuArrow = document.querySelector(".c-header-menu__mobile-arrow");
        initScripts(); /* INITALIZE SCRIPTS */
        screenResize(); /* ON SCREEN RESIZE ACTIONS */
      });
    }
  }
});
